import { PageLinkObject } from './PageLinks'
import ListItem from '@mui/material/ListItem/ListItem'
import ListItemButton from '@mui/material/ListItemButton/ListItemButton'
import { Link } from 'react-router-dom'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import * as React from 'react'

export default function PageLinkListItem(props: {
    pageLink: PageLinkObject
    pathname: string
    open: boolean
}) {
    return (
        <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
                selected={props.pageLink.pageLink === props.pathname}
                component={Link}
                to={props.pageLink.pageLink}
                sx={{
                    minHeight: 48,
                    justifyContent: props.open ? 'initial' : 'center',
                    px: 2.5,
                }}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: props.open ? 3 : 'auto',
                        justifyContent: 'center',
                    }}
                >
                    {props.pageLink.icon}
                </ListItemIcon>
                <ListItemText
                    disableTypography
                    primary={
                        <Typography variant={'button'}>
                            {props.pageLink.title}
                        </Typography>
                    }
                    sx={{ opacity: props.open ? 1 : 0 }}
                />
            </ListItemButton>
        </ListItem>
    )
}
