import SvgIcon from '@mui/material/SvgIcon'
import * as React from 'react'

export default function TelerikLogo() {
    return (
        <SvgIcon>
            <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="100%"
                viewBox="0 0 512 512"
            >
                <path
                    fill="#5CE400"
                    opacity="1.000000"
                    stroke="none"
                    d="M189.428467,513.000000 
	C189.000000,447.340973 189.000000,381.681946 189.000000,316.508667 
	C180.766098,311.440796 172.913528,306.384460 164.840500,301.708679 
	C158.262787,297.898926 151.355820,294.661560 144.719543,290.947601 
	C140.204285,288.420654 135.941193,285.447327 131.496048,282.789490 
	C128.114563,280.767639 124.590126,278.985992 121.190315,276.993347 
	C116.595985,274.300568 112.072403,271.487366 107.495026,268.765259 
	C104.238289,266.828522 100.928169,264.981628 97.668243,263.050140 
	C92.820488,260.177887 88.015572,257.232941 83.154663,254.383377 
	C80.022095,252.547012 76.791039,250.878616 73.659180,249.041092 
	C68.798676,246.189346 64.005646,243.222565 59.144215,240.372421 
	C56.011635,238.535858 52.775108,236.876511 49.643513,235.038345 
	C44.783703,232.185776 40.040157,229.129517 35.124245,226.379623 
	C29.953238,223.487061 24.637501,220.853226 19.135380,217.975769 
	C19.135380,216.857895 19.135380,215.376038 19.135380,214.382019 
	C27.256090,209.438705 35.105053,204.516846 43.097809,199.840775 
	C49.530193,196.077576 56.177906,192.684204 62.651901,188.989746 
	C67.410645,186.274124 72.026878,183.309891 76.737328,180.508102 
	C79.858826,178.651413 83.063187,176.933777 86.179565,175.068848 
	C91.136353,172.102600 96.018456,169.010681 100.995049,166.078705 
	C104.001678,164.307343 107.181305,162.829514 110.186958,161.056641 
	C115.162262,158.121964 119.917702,154.778519 125.043488,152.148529 
	C128.122299,150.568802 131.707443,149.938049 135.108322,149.045822 
	C136.199753,148.759476 137.464279,149.184464 138.590729,148.971924 
	C147.723846,147.248657 153.757034,153.560989 160.673782,157.487885 
	C167.132050,161.154495 173.414017,165.130341 179.843185,168.849792 
	C186.036255,172.432693 192.328171,175.844345 198.559219,179.362122 
	C208.997681,185.255234 219.502869,191.036514 229.821945,197.132355 
	C235.827835,200.680252 241.347092,205.063156 247.402908,208.512817 
	C257.586548,214.313873 268.114105,219.510025 278.318024,225.277222 
	C284.062653,228.524048 289.384705,232.509689 295.018402,235.964691 
	C299.150696,238.498947 303.457397,240.754028 307.729950,243.052505 
	C310.512024,244.549179 311.067139,246.819214 311.060394,249.834717 
	C310.969116,290.663849 310.999176,331.493225 310.999237,372.322540 
	C310.999268,391.154022 310.565735,409.999359 311.167755,428.811554 
	C311.508820,439.470062 305.939056,446.478790 298.668610,452.264404 
	C293.145233,456.659790 286.287506,459.367706 280.061798,462.894104 
	C273.704407,466.495056 267.415680,470.217346 261.055847,473.813934 
	C255.516327,476.946625 249.839554,479.843292 244.369888,483.090942 
	C238.486740,486.584167 232.850037,490.492493 226.966797,493.985565 
	C220.566376,497.785614 213.993729,501.294617 207.519974,504.972443 
	C203.336716,507.349060 199.196274,509.801025 195.018311,512.609619 
	C193.285645,513.000000 191.571289,513.000000 189.428467,513.000000
z"
                />
                <path
                    fill="#5CE400"
                    opacity="1.000000"
                    stroke="none"
                    d="
M421.998566,96.998123 
	C427.670563,100.391068 432.924622,103.705437 438.240387,106.917732 
	C444.477753,110.686920 450.730499,114.433891 457.047150,118.067787 
	C463.502502,121.781487 470.040466,125.351707 476.547913,128.974625 
	C480.669678,131.269333 484.794586,133.558640 488.933685,135.821854 
	C490.130524,136.476257 491.381348,137.031967 492.999390,137.825043 
	C492.999390,139.492432 492.999451,141.474213 492.999451,143.455978 
	C492.999420,230.599884 492.983612,317.743774 493.055176,404.887634 
	C493.058136,408.494354 491.980133,410.867279 489.392975,413.624634 
	C483.767456,419.620209 477.352142,423.739929 469.971039,427.384003 
	C460.369019,432.124481 451.851166,439.012207 442.600067,444.528656 
	C436.068848,448.423187 429.002533,451.409515 422.350067,455.115387 
	C417.829620,457.633606 413.651154,460.758636 409.245789,463.493134 
	C406.162292,465.407135 402.923645,467.069275 399.808228,468.933990 
	C394.852936,471.899872 389.975769,474.997162 385.000977,477.929260 
	C381.994751,479.701111 378.854401,481.244019 375.796600,482.930206 
	C372.868317,484.544952 369.310242,485.629547 367.216431,487.997223 
	C364.324738,491.267151 361.249969,491.303833 357.000183,490.649597 
	C357.000183,488.648438 357.000214,486.539948 357.000214,484.431427 
	C357.000153,398.120636 356.866150,311.809296 357.225311,225.500000 
	C357.258087,217.628113 353.743805,214.205124 347.836334,211.006912 
	C341.378296,207.510620 335.223083,203.458923 328.877869,199.748474 
	C326.261719,198.218613 323.413300,197.083572 320.810272,195.534149 
	C310.694702,189.512863 300.731293,183.229797 290.520477,177.376923 
	C283.921356,173.594269 276.891388,170.568283 270.242889,166.864960 
	C265.721649,164.346573 261.589081,161.140411 257.164856,158.434525 
	C253.941055,156.462830 250.467422,154.903320 247.208145,152.985077 
	C242.483475,150.204391 237.885086,147.209686 233.172241,144.408157 
	C226.728699,140.577835 220.216248,136.863632 213.755676,133.061646 
	C207.475098,129.365570 201.247360,125.579247 194.950302,121.911743 
	C189.750961,118.883568 184.452209,116.025917 179.255722,112.992958 
	C173.154099,109.431709 167.147873,105.706741 161.040466,102.155617 
	C154.551956,98.382889 148.002945,94.713669 141.461868,91.032082 
	C137.350845,88.718231 133.212067,86.453308 129.072144,84.191353 
	C127.875145,83.537323 126.631012,82.969574 125.174744,82.246162 
	C125.174744,80.186935 125.174744,77.895027 125.174744,76.493752 
	C130.150131,73.352943 134.546463,70.363190 139.145111,67.726318 
	C145.614349,64.016861 152.275909,60.644108 158.774643,56.984276 
	C163.411987,54.372704 167.907196,51.509663 172.484039,48.789463 
	C175.740829,46.853821 179.041336,44.991722 182.298630,43.056885 
	C188.562042,39.336399 194.771103,35.522846 201.073792,31.870537 
	C206.332489,28.823204 211.729263,26.014622 216.998276,22.984503 
	C222.936295,19.569660 229.077393,16.414038 234.632080,12.448051 
	C243.460403,6.144694 253.144409,2.891261 263.608734,5.339932 
	C269.030029,6.608527 273.775513,10.765429 278.826141,13.617776 
	C289.122650,19.432734 299.480927,25.141790 309.691620,31.103720 
	C315.595184,34.550770 321.161346,38.575085 327.059845,42.031620 
	C333.611450,45.870880 340.420471,49.268501 347.018280,53.032207 
	C351.645874,55.672035 356.090576,58.630810 360.648987,61.394306 
	C363.754883,63.277225 366.946899,65.018547 370.045593,66.912849 
	C375.091003,69.997253 380.009338,73.297897 385.131805,76.245697 
	C391.593903,79.964386 398.245941,83.351494 404.743439,87.010544 
	C410.417175,90.205704 415.990356,93.579514 421.998566,96.998123 
z"
                />
                <path
                    fill="#5CE400"
                    opacity="1.000000"
                    stroke="none"
                    d="
M20.508751,410.800964 
	C30.413141,404.849792 39.848145,398.884552 49.538734,393.368378 
	C56.287464,389.526794 63.547443,386.581055 70.281425,382.716522 
	C76.189240,379.326141 81.489418,374.875549 87.402374,371.495789 
	C97.574745,365.681396 108.095734,360.479279 118.311081,354.736755 
	C124.005775,351.535492 129.329330,347.680023 134.930023,344.301544 
	C136.440720,343.390228 138.352783,343.144257 140.627579,342.418365 
	C140.627579,389.564392 140.627579,435.942444 140.627579,482.688354 
	C135.163025,484.238800 131.496033,479.812439 127.138718,478.052887 
	C122.827110,476.311798 118.974411,473.422241 114.947372,470.991760 
	C110.520409,468.319916 106.147331,465.558319 101.707108,462.908997 
	C98.318275,460.887024 94.840057,459.015320 91.439812,457.011902 
	C86.720726,454.231384 82.057800,451.355347 77.331787,448.586884 
	C74.064339,446.672821 70.701141,444.922211 67.434044,443.007599 
	C62.708672,440.238342 58.052380,437.351135 53.326653,434.582489 
	C50.059322,432.668274 46.693893,430.921509 43.427151,429.006317 
	C38.702297,426.236237 34.078785,423.290009 29.311922,420.596039 
	C26.291504,418.889038 23.089142,417.503967 18.998041,415.498962 
	C19.206800,414.678680 19.677635,412.828674 20.508751,410.800964 
z"
                />
            </svg>
        </SvgIcon>
    )
}
