import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { Card, Fade, Tooltip } from '@mui/material'
import Chip from '@mui/material/Chip'
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded'
import BackspaceRoundedIcon from '@mui/icons-material/BackspaceRounded'
import * as React from 'react'
import Box from '@mui/material/Box'

export function CellDeleteHelpBanner(props: {
    selectedCellsLength: number
    userSelection: boolean
    onDelete: () => void
}) {
    return (
        <Stack
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            sx={{ mb: 1 }}
            direction="row"
        >
            <Typography variant={'h6'}>Cells Selected:</Typography>
            <Box sx={{ minWidth: '60px' }}>
                <Tooltip
                    placement={'top'}
                    title={`Try ${
                        props.userSelection
                            ? 'deselecting the'
                            : 'selecting some'
                    } cells`}
                >
                    <Chip
                        variant={'outlined'}
                        color={props.userSelection ? 'primary' : 'warning'}
                        label={props.selectedCellsLength}
                        onDelete={
                            props.userSelection ? props.onDelete : undefined
                        }
                        icon={
                            props.userSelection ? undefined : (
                                <WarningAmberRoundedIcon />
                            )
                        }
                    />
                </Tooltip>
            </Box>
            <Fade
                in={props.userSelection}
                style={{
                    transitionDelay: props.userSelection ? '300ms' : '0ms',
                }}
            >
                <Card
                    sx={(theme) => ({
                        py: 0.25,
                        px: 1,
                        backgroundColor: theme.palette.grey[300],
                    })}
                >
                    <Stack
                        justifyContent="flex-start"
                        alignItems="center"
                        direction="row"
                        spacing={1}
                    >
                        <Typography variant={'caption'}>
                            ... Now press
                        </Typography>
                        <BackspaceRoundedIcon
                            fontSize={'small'}
                            color={'action'}
                        />
                    </Stack>
                </Card>
            </Fade>
        </Stack>
    )
}
