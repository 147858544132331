import * as React from 'react'
import MuiLogo from './muiLogo'
import TelerikLogo from './telerikLogo'
import MuiRowSpanning from './MuiRowSpanning'
import { TelerikRowSpanning } from './TelerikRowSpanning'
import HomeIcon from '@mui/icons-material/Home'
import { HomeScreen } from './HomeScreen'
import MuiDeleteCells from './MuiDeleteCells'

export type PageLinks =
    | '/'
    | '/mui-delete-cells'
    | '/mui-row-spanning'
    | '/telerik-row-spanning'

export type PageLinkObject = {
    pageLink: PageLinks
    icon: React.ReactNode
    title: string
    content: React.ReactNode
}

export const pageLinks: PageLinkObject[] = [
    {
        pageLink: '/',
        icon: <HomeIcon color={'secondary'} />,
        title: 'Home',
        content: <HomeScreen />,
    },
    {
        pageLink: '/mui-delete-cells',
        icon: <MuiLogo />,
        title: 'MUI Delete Cells',
        content: <MuiDeleteCells />,
    },
    {
        pageLink: '/mui-row-spanning',
        icon: <MuiLogo />,
        title: 'MUI Row Spanning',
        content: <MuiRowSpanning />,
    },
    {
        pageLink: '/telerik-row-spanning',
        icon: <TelerikLogo />,
        title: 'Telerik Row Spanning',
        content: <TelerikRowSpanning />,
    },
]
