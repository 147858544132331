import * as React from 'react'
import {
    DataGridPremium,
    GridCellSelectionModel,
    GridEventListener,
    GridRowModelUpdate,
    useGridApiRef,
} from '@mui/x-data-grid-premium'
import { useDemoData } from '@mui/x-data-grid-generator'
import { CellDeleteHelpBanner } from './CellDeleteHelpBanner'

export default function MuiDeleteCells() {
    const { data } = useDemoData({
        dataSet: 'Commodity',
        rowLength: 10,
        maxColumns: 6,
    })

    const apiRef = useGridApiRef()
    const [selectedCellsLength, setSelectedCellsLength] = React.useState(0)
    const [cellSelectionModel, setCellSelectionModel] =
        React.useState<GridCellSelectionModel>({})

    // this takes the latest selection from the grid and sets it as state
    // the state is passed to the grid as a prop
    // now we can always check what is selected
    const handleCellSelectionModelChange = React.useCallback(
        (newModel: GridCellSelectionModel) => {
            setCellSelectionModel(newModel)
        },
        []
    )

    // every time the selection is different, display the value
    React.useEffect(() => {
        const selectedCells = apiRef.current.unstable_getSelectedCellsAsArray()
        setSelectedCellsLength(selectedCells.length)
    }, [apiRef, cellSelectionModel])

    const handleDelete: GridEventListener<'cellKeyDown'> = (
        params, // GridCellParams<any>
        event, // MuiEvent<React.KeyboardEvent<HTMLElement>>
        details // GridCallbackDetails
    ) => {
        if (!(event.key === 'Delete' || event.key === 'Backspace')) {
            return
        }

        const updates: GridRowModelUpdate[] = []
        Object.entries(cellSelectionModel).forEach(([id, fields]) => {
            const updatedRow = { ...apiRef.current.getRow(id) }
            // console.log('this is the row info', updatedRow)
            Object.entries(fields).forEach(([field, isSelected]) => {
                if (isSelected) {
                    updatedRow[field] = undefined
                }
            })
            updates.push(updatedRow)
        })
        // console.log('these are the updates', updates)
        apiRef.current.updateRows(updates)
    }

    const handleDeleteSelectedCellsChip = () => {
        setCellSelectionModel({})
    }

    return (
        <div style={{ width: '100%' }}>
            <CellDeleteHelpBanner
                selectedCellsLength={selectedCellsLength}
                userSelection={selectedCellsLength > 0}
                onDelete={handleDeleteSelectedCellsChip}
            />
            <div style={{ height: '100%' }}>
                <DataGridPremium
                    onCellKeyDown={handleDelete}
                    apiRef={apiRef}
                    rowSelection={false}
                    unstable_cellSelection
                    unstable_cellSelectionModel={cellSelectionModel}
                    unstable_onCellSelectionModelChange={
                        handleCellSelectionModelChange
                    }
                    {...data}
                />
            </div>
        </div>
    )
}
