import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { CSSObject, styled, Theme } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import * as React from 'react'
import { Link, Route, Routes, useLocation } from 'react-router-dom'
import MuiDrawer from '@mui/material/Drawer'
import { PageLinkObject } from './PageLinks'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import Button from '@mui/material/Button'
import ReplyRoundedIcon from '@mui/icons-material/ReplyRounded'
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined'
import PageLinkListItem from './PageLinkListItem'

const drawerWidth = 260

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
})

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1), // necessary for content to be below app bar
    minHeight: 48,
}))

interface AppBarProps extends MuiAppBarProps {
    open?: boolean
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}))

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}))

export default function MyDrawer({
    pageLinks,
}: {
    pageLinks: PageLinkObject[]
}) {
    const location = useLocation()
    const [open, setOpen] = React.useState(false)

    const handleDrawerOpen = () => {
        setOpen(true)
    }

    const handleDrawerClose = () => {
        setOpen(false)
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar position="fixed" open={open}>
                <Toolbar variant={'dense'}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 4,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Button
                        startIcon={
                            location.pathname === '/' ? (
                                <CottageOutlinedIcon sx={{ pb: 0.25 }} />
                            ) : (
                                <ReplyRoundedIcon sx={{ pb: 0.25 }} />
                            )
                        }
                        size={'small'}
                        variant={'outlined'}
                        disableTouchRipple
                        component={Link}
                        to={'/'}
                        color={'inherit'}
                    >
                        {
                            pageLinks.find(
                                (pageLink) =>
                                    pageLink.pageLink === location.pathname
                            )?.title
                        }
                    </Button>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List sx={{ pt: 0 }}>
                    {pageLinks
                        .filter((pageLink) => pageLink.pageLink !== '/')
                        .map((pageLink) => (
                            <PageLinkListItem
                                key={pageLink.title}
                                pageLink={pageLink}
                                pathname={location.pathname}
                                open={open}
                            />
                        ))}
                </List>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Toolbar variant={'dense'} />
                <Routes>
                    {pageLinks.map((pageLink) => (
                        <Route
                            path={pageLink.pageLink}
                            element={pageLink.content}
                        />
                    ))}
                </Routes>
            </Box>
        </Box>
    )
}
